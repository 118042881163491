<template>
  <div
    v-if="show"
    ref="pendingFeesModal"
    :class="{ show: show === true }"
    :style="{ display: 'block' }"
    class="modal modal__confirm"
    id="pendingFeesModal"
    tabindex=""
    role="dialog"
    aria-labelledby="modalLabel"
    aria-hidden="true"
  >
    <div class="w-100 h-100 d-flex">
      <div class="modal__dialog" role="document">
        <div class="modal__header mb-3">
          <div class="d-flex align-items-center justify-content-between">
            <span class="modal__header__title text--capital"> Apply Fee </span>
            <a
              data-dismiss="#pendingFeesModal"
              class="
                modal__close modal__close--white
                d-flex
                align-items-center
                justify-content-between
              "
              @click="$emit('close')"
            >
              <span class="text--capital text--regular"> close </span>
              <ion-icon name="close-outline"></ion-icon>
            </a>
          </div>
        </div>
        <div class="modal__content">
          <div class="modal__body">
            <form>
              <div class="row">
                <div class="col-6">
                  <div class="form__item">
                    <label class="form__label">Fee</label>
                    <select
                      class="select select--lg"
                      required
                      v-model="selectedFee"
                      @change="popAmountFee"
                    >
                      <option value="" disabled selected>Select Fee</option>
                      <option
                        v-for="fee in productFee"
                        :key="fee.id"
                        :value="fee"
                      >
                        {{ fee.name }}
                      </option>
                      <option value="Other" v-if="!this.isArbitraryFeeAllowed">
                        Other
                      </option>
                    </select>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-12">
                  <div class="form__item">
                    <label class="form__label">Amount</label>
                    <div class="input-group">
                      <div class="input-group-prepend">
                        <span
                          class="
                            w-100
                            d-flex
                            text--medium text-white text--600
                            align-items-center
                            justify-content-center
                          "
                        >
                          ₦
                        </span>
                      </div>
                      <input
                        type="text"
                        class="input input--right form__input form__input--lg"
                        placeholder="Enter Amount"
                        :value="amount"
                        :disabled="checkForBalance"
                        @input="numberIsValid(amount)"
                        @blur="formatAndValidateAmount($event)"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div class="form__item">
                <div class="form__item">
                  <label
                    class="form__label text--regular-alt text--bold text--black"
                    >notes</label
                  >
                  <textarea
                    cols="5"
                    rows="5"
                    class="input form__input"
                    placeholder=""
                    v-model="applyFee.note"
                  ></textarea>
                </div>
              </div>
              <div class="form__item">
                <div class="form__action">
                  <button
                    data-dismiss="#confirmModal"
                    class="button form__button form__button--lg save-changes"
                    v-if="loading"
                  >
                    <div class="spinner-border texxt-light" role="status"></div>
                  </button>
                  <button
                    type="button"
                    class="
                      button
                      form__button form__button--lg
                      w-30
                      save-changes
                    "
                    v-else
                    @click.prevent="saveApplyFee"
                  >
                    Apply Fee
                  </button>
                  <a
                    role="button"
                    class="
                      modal__close
                      form__action__text form__action__text--danger
                      text--capital
                      ml6
                    "
                    @click="$emit('close')"
                  >
                    cancel
                  </a>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ApiService from "@/core/services/api.service";
import currencyMixin from "@/mixins/currencyMixin"; 
export default {
  name: "applyFee",
  mixins: [currencyMixin],
  props: {
    show: Boolean,
    close: Function,
    accountDetails: [Array, Object],
  },
  data() {
    return {
      applyFee: {
        accountSavingKey: this.accountDetails.savingsAccountKey,
        feeName: "",
        amount: "",
        note: "",
        allowarbitaryFee: this.accountDetails.allowAbitraryFee,
      },
      amount: "",
      selectedFee: {},
      loading: false,
    };
  },
  computed: {
    productFee() {
      let data = []
      if(this.accountDetails.allowAbitraryFee) {
        data = [...this.accountDetails.productFee, { amount: null, name: "Apply Arbitrary Fee", isActive: true, feeTrigger: "Manual" }]
      } else {
        data = this.accountDetails.productFee
      }
      return data.filter(fee => fee.isActive);
    },
    isArbitraryFeeAllowed() {
      return this.accountDetails.allowAbitraryFee ? true : false;
    },
    checkForBalance() {
      return this.selectedFee.amount || this.selectedFee.amount == 0 ? true : false;
    },
  },
  methods: {
    popAmountFee() {
      this.applyFee.feeName = this.selectedFee.name;
      this.amount = this.applyFeeInputCurrencyFormat(this.selectedFee.amount);
      if (this.selectedFee === "Other") {
        this.applyFee.feeName = this.selectedFee;
        this.amount = this.applyFeeInputCurrencyFormat(this.selectedFee);
      }
    },
    numberIsValid(amount) {
      if(!this.isValidNumberInput(amount)) return true;
      return false;
    },
    formatAndValidateAmount(amount) {
      const amountValue = amount.target.value;
      const amtPaid = this.convertFigureToNumber(amountValue);
      const amountInputted = this.inputCurrencyFormat(amtPaid);

      if (this.validateDepositAmount(amountValue)) {
        this.applyFee.amount = amtPaid;
        this.amount = amountInputted;
        return;
      }
      this.applyFee.amount= this.applyFee.amount;
      this.amount = '';
    },
    saveApplyFee() {
      this.loading = true;
      this.applyFee.amount = this.convertFigureToNumber(this.amount);
      const formLoad = {
        ...this.applyFee,
        accountSavingKey: this.accountDetails.savingsAccountKey,
      };
      ApiService.post(
        "DepositAccount/createappliedmanualfee",
        formLoad
      )
        .then((response) => {
          if (response.data.status !== false) {
            this.loading = false;
            this.$emit("confirm-apply-fee-successful", response.data.message);
            this.amount = "";
            this.selectedFee = "";
            this.resetForm();
          } else {
            this.loading = false;
            this.$emit("error-apply-fee-modal", response.data.message);
          }
          if (response.status == 400) {
            this.$emit("error-apply-fee-modal", response.data.message);
          }
          this.amount = "";
          this.selectedFee = "";
          this.resetForm();
        })
        .catch((error) => {
          this.loading = false;
          this.$emit("error-apply-fee-modal", error.response.data.message);
        });
    },
    resetForm() {
      this.applyFee = {
        accountSavingKey: '',
        feeName: "",
        amount: "",
        note: "",
        allowarbitaryFee: this.isArbitraryFeeAllowed,
      };
    },
    populateForm() {
      this.applyFee = {
        accountSavingKey: this.accountDetails.savingsAccountKey,
        feeName: "",
        amount: "",
        note: "",
        allowarbitaryFee: this.accountDetails.allowAbitraryFee,
      };
      this.amount = "";
      this.selectedFee = {};
    },
  },
  mounted() {
    this.populateForm();
  },
};
</script>

<style scoped>
.text--600 {
  font-family: Montserrat-semibold !important;
}
.text--primary2 {
  color: #f4b740 !important;
}
.text--regular,
.text--regular-alt {
  font-size: 1.4rem !important;
}
</style>
